import React/* , { useState } */ from "react"
import { graphql/* , Link */ } from "gatsby"
//import Layout from "../components/layout"
import Layout from "../components/layout-minhelt/layout"
import Seo from "../components/Seo"
import getMetatags from "../util/getMetatags"
//import useViewportWidth from "../hooks/useViewportWidth"
import TextBox from "../components/text-box"
import { HeroMinhelet } from "../components/hero"
import TwoColsContainer from "../components/two-cols-container"
import Carusel from "../components/carusel"
import Image from "../components/Image"
import Activities from "../components/activities"

const Basic1Page = ({ data, ...props }) => {

  const seo = getMetatags(data.node.metatag/* , nodeOverview.relationships.OGImage?.localFile?.publicURL */)
  const { hero, twoCol, double, single, slides } = data.node.relationships


  return (
    <Layout>
      <Seo {...seo} />
      <HeroMinhelet
        className="mt-[90px] lg:mt-[126px]  h-[147px] lg:h-[350px] "
        {...hero}
        url={hero?.relationships?.image?.localFile?.publicURL}
      />
      {twoCol && <TwoColsContainer
        title={twoCol.title}
        className="my-12 titles"
        col1={<div className="text-lg html-block text-justify" dangerouslySetInnerHTML={{ __html: twoCol.html1?.processed }} />}
        col2={<div className="text-lg html-block text-justify" dangerouslySetInnerHTML={{ __html: twoCol.html2?.processed }} />}
      />}
      {single && single.map((e, index) => (
        <TwoColsContainer
          key={index}
          col1={<TextBox
            title={e?.title}
            html={e?.html?.processed}
            className="mb-12 py-8 titles "
            htmlClassName="text-justify"
          />}
          col2={
            <div className="h-full bg-[url('/backgrounds/pattern1.svg')] bg-no-repeat bg-left-top pt-10"
            >
              <Carusel fade={true} items={e.relationships.images.map((ee, i) => (
                <Image image={ee} alt={e.images[i].alt} key={i} />
              ))} />
            </div>
          }
        />
      ))}
      {double && <TwoColsContainer
        title={double.title}
        className="my-12 titles"
        col1={<div className="text-lg html-block text-justify" dangerouslySetInnerHTML={{ __html: double?.html1?.processed }} />}
        col2={<div className="text-lg html-block text-justify" dangerouslySetInnerHTML={{ __html: double?.html2?.processed }} />}
      />}
      {slides.length > 0 && <Section4 /* title="title" */ items={slides} />}

      {/* <TwoColsContainer
        col1={<TextBox
          title={double[0].title}
          html={double[0].html.processed}
          className="mb-12 py-8"
        />}
        col2={<TextBox
          title={double[1].title}
          html={double[1].html.processed}
          className="p-8"
        />}
      /> */}
      {/* <TwoColsContainer
        col1={<TextBox
          title={single.title}
          html={single.html.processed}
          className="mb-12 py-8"
        />}
        col2={<div className="h-full bg-[url('/backgrounds/pattern1.svg')] bg-no-repeat bg-left-top"
        ></div>}
      /> */}



    </Layout >
  )
}


const Section4 = ({ items, ...props }) => {

  return (
    <section className="lg:py-32 py-12">
      <div className="container space-y-12">
        <Activities items={items} className="" />
      </div>
    </section>
  )
}


export default Basic1Page

/* export const Head = () => <title>Basic1 Page</title> */

export const query = graphql`
  query innerPageMinheletQuery($id: String!) {
    node: nodeInnerPageMinhelet(id: { eq: $id }) {
      id
      title
      metatag {
        attributes {
          content
          name
        }
        tag
      }
      relationships {
        hero: field_hero {
          ...Hero
        }
        twoCol: field_paragraph {
          ...TwoHtmlBlocks
        }
        double: field_paragraph1 {
          ...TwoHtmlBlocks
        }
        single: field_paragraphx2 {
          ...TitleHtmlImage
        }
        slides: field_paragraph_repeater{
          ...TitleTextImages
        }


      }
    }
  }
`
